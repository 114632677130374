.table {
  thead {
    tr th {
      border: none;
    }
  }

  &.centered {
    tbody {
      td,
      tr {
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      td {
        a {
          display: block;
          color: $dark-gray--primary;

          &:hover {
            color: $dark-gray--primary;
          }
        }
      }

      &.active > td {
        background-color: $light-gray;
      }

      &.inactive > td {
        background-color: lighten($error-red, 25%);
      }

      &.disabled > td {
        background-color: $grey-disabled;
        color: $brown;
      }
    }
  }

  &.no-border {
    tr > td {
      border: 0;
    }

    &.small {
      font-size: 14px;

      tr > td {
        padding: 2px;
      }
    }
  }

  &.no__padding--left {
    tr > td {
      padding-left: 0;
    }
  }

  &.table-hover {
    tbody {
      tr:hover {
        background-color: darken($full-white, 10%);
        border-color: darken($tv4-red, 4%);
      }
    }
  }

  &.table-striped {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba($mid-red-secondary, 0.05);
      }

      tr {
        margin: 2px 0;
        border-left: 4px solid $tv4-red;
      }
    }

    &.table-hover {
      tbody {
        tr:nth-child(odd) {
          &:hover {
            background-color: darken(rgba($mid-red-secondary, 0.05), 10%);
          }
        }

        tr:hover {
          margin: 2px 0;
          border-left: 4px solid $hover--red;
        }
      }
    }
  }

  &.table--campaign {
    td {
      width: 50%;
    }
  }

  &.table--summary {
    tbody {
      tr {
        &:nth-child(even) {
          background-color: darken($highlighted-gray, 2%);
        }
      }
    }
  }

  &.table--auto--width {
    width: auto;
  }
}

.table--fixed--first-column {
  tr {
    td:first-child {
      width: 200px;
    }
  }
}

.table--highlighted--first-column {
  tr {
    td:first-child {
      background-color: $highlighted-gray;
    }
  }
}

.table--fixed--last-column {
  tr {
    td:last-child {
      width: 200px;
    }
  }
}

.table--layout--fixed {
  table-layout: fixed;

  td {
    word-wrap: break-word;
  }
}

.table-woo,
.table__woo,
.table--woo {
  border-spacing: 0;
  margin: 0 !important;
  padding: 18px;
  border: 1px solid $mid-gray--lightest;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 0;
  color: $dark-gray--primary;

  thead {
    background-color: $full-white;
    color: $dark-gray--primary;
    font-weight: $light;
    border: 0;
    padding: 8px !important;

    tr th {
      font-weight: $medium;
    }
    th, td {
      padding: 8px;
      text-indent: 0;
    }
  }

  tbody {
    border: 0;
    background-color: $full-white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    color: $dark-gray--primary;

    tr {
      color: $dark-gray--primary;
      border-bottom: 1px solid $mid-gray--lightest;

      & > td {
        padding: 12px 8px !important;
        color: $dark-gray--primary;
        border-top: 1px solid $mid-gray--lightest;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  &.table--no-border {
    border: 0;

    tr > td,
    tr > th {
      border: 0;
    }
  }

  &.table--small {
    tbody {
      tr {
        & > td {
          padding: 4px !important;
          vertical-align: middle;
        }

        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  &.table--hover {
    tbody {
      tr {
        @extend .animate--fast;

        &:hover {
          background-color: $highlighted-gray;
        }
      }
    }
  }

  &.table--align--middle {
    tbody {
      td {
        vertical-align: middle;
      }
    }
  }

  &.table--info,
  &.thead--info {
    thead {
      background-color: $dark-gray;
      color: $full-white;
    }
  }

  &.table--light-red,
  &.thead--light-red {
    thead {
      background-color: $light-red-secondary;
      color: $dark-red-secondary;
    }
  }

  &.table--brown,
  &.thead--brown {
    thead {
      background-color: $brown;
      color: $full-white;
    }
  }

  &.table--min-width {
    td {
      min-width: 150px;
    }
  }

  &.table--system--messages {
    tr {
      &.info {
        td {
          background-color: $full-white;
          color: $dark-gray;
        }
      }

      &.warning {
        td {
          background-color: $warning-yellow;
          color: $full-white;
        }
      }
    }
  }
}

.table--small,
.table__small {
  margin: 0;
  padding: 0;
  font-size: 14px;

  tr {
    td {
    }

    &.sub {
      font-size: 12px;
    }
  }
}

.table-50-width-columns {
  th,
  td {
    width: 50%;
  }
}

.thead--info--pill {
  thead {
    border: none;

    tr th {
      border-bottom: none;
    }

    tr th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    tr th:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }
}

.thead--half-pill {
  thead {
    border: none;

    tr th {
      border-bottom: none;
    }

    tr th:first-child {
      border-top-left-radius: 10px;
    }

    tr th:last-child {
      border-top-right-radius: 10px;
    }
  }

  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }
}

table.table--with-small-images {
  tbody {
    tr td {
      img {
        width: 75px;
      }
    }
  }
}

.table--medium-font-header {
  thead {
    th {
      font-weight: $medium;
    }
  }
}

.table--light-font-header {
  thead {
    th {
      font-weight: $light;
    }
  }
}

//Table with span element
.grid-columns--6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  &.button-colum-right {
    grid-template-columns: repeat(6, 1fr) 60px;
  }
}

.grid-columns--4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-columns--3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
