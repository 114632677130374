input[type='checkbox'] {
  float: left;
  width: initial;
}

input[type='radio'] {
  float: left;
  width: initial;
}

.input__item {
  height: 40px;
  width: 100%;
  display: block;
  background-color: $full-white;
  font-size: 16px;
  color: $dark-gray--primary;
  box-sizing: border-box;
  border: 1px solid $mid-gray--darker;
  border-radius: $border-radius-soft;
  padding: 0px $spacing-1x;
  margin: 0;

  &:invalid {
    box-shadow: none;
  }

  &:-webkit-autofill {
    background-color: $full-white !important;
    color: $darkest-gray !important;
  }

  &.ng-valid {
    border-color: $positive-feedback--green;
  }

  &.ng-invalid {
    border-color: $error-red;
  }

  &.ng-pristine {
    border-color: $mid-gray--darker;
  }

  &.no-valid-color {
    border-color: $mid-gray--darker;
  }

  &:focus,
  &.ng-focus {
    outline: none;
    border: 2px solid $focus-blue;
  }

  &:disabled {
    background-color: $light-gray;
  }

  &:hover {
    border-color: $pink;
    background-color: $pink--pale;
  }
}

.input__container,
.select__container {
  position: relative;
  margin-bottom: $spacing-2x;

  input,
  textarea,
  select {
    @extend .input__item;
  }

  textarea {
    height: inherit;
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: $regular;
    margin-bottom: 3px;
  }

  .input__optional__text {
    font-size: 14px;
    color: $dark-gray;
    position: absolute;
    right: 0;
  }

  .input__help__text {
    font-size: 12px;
    display: block;
    margin-top: 3px;
  }

  textarea {
    padding: $spacing-1x;
  }

  .input__status {
    position: absolute;
    top: 53%;
    right: 10px;
  }

  .icon {
    position: relative;
    display: inline-block;
    font-size: 1.2rem;
  }
}

.input__error__text {
  font-size: 12px;
  color: $error-red;
  margin-top: -$spacing-1x;
  margin-bottom: $spacing-1x;
  font-weight: $light;
}

select.ng-pristine ~ input-errors .input__error__text,
select.ng-pristine ~ .input__error__text,
textarea.ng-pristine ~ input-errors .input__error__text,
textarea.ng-pristine ~ .input__error__text,
input.ng-pristine ~ input-errors .input__error__text,
input.ng-pristine ~ .input__error__text {
}

.select__container {
  position: relative;

  &.no-label {
    &:before {
      top: 23%;
    }
  }

  select {
    @include appearance(none);
  }

  &:before {
    position: absolute;
    top: 50%;
    right: $spacing-1x;
    color: $tv4-red;
    z-index: 2;
    font-family: 'tv4-icon-font';
    font-size: 25px;
    line-height: 1;
    content: '\e90a';
    pointer-events: none;
  }
}

input[type='submit'] {
  width: inherit;
  border: 0;
  padding: $spacing-1x $spacing-3x;
  &.float {
    float: left;
  }
}

/**
* Disables native date pickers
*/
hidden-native-datepicker::-webkit-calendar-picker-indicator,
hidden-native-datepicker::-webkit-inner-spin-button,
hidden-native-datepicker::-webkit-input-placeholder {
  display: none;
}

// Checkboxes
input[type='checkbox'] {
  &.checkbox {
    @include appearance(none);
    outline: none;
    background-color: $light-gray--primary;
    border: 1px solid $mid-gray--darkest;
    margin-top: $spacing-1x;
    padding: $spacing-1x;
    position: relative;
    border-radius: 6px;
    height: 16px;
    width: 16px;

    & + label.checkbox {
      display: inline-block;
      font-size: 1rem;
      line-height: 1;
      margin: $spacing-1x 12px;
      font-weight: $regular;
    }

    &:checked {
      background-color: $green;
      border-color: $green;
      &:after {
        opacity: 1;
        font-size: 1rem;
        content: '\e96c';
        font-family: 'tv4-icon-font';
        font-size: 14px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: transparent;
        color: $full-white;
        line-height: 1;
      }
    }
  }

  &:disabled,
  &.disabled {
    &.checkbox {
      background-color: $mid-gray--darker;
      border-color: $darker-gray;
      & + label.checkbox {
        color: $mid-gray--darker;
      }
      &:after {
        color: $mid-gray--darker;
        background-color: $mid-gray--darker;
      }
      &.show-disabled-check {
        &:after {
          color: $mid-gray--darker;
        }
      }
    }
  }
}

.long-label {
  input[type='checkbox'] {
    &.checkbox {
      margin-top: 0;

      & + label.checkbox {
        display: block;
        line-height: 1.3;
        margin: $spacing-1x 12px $spacing-1x $spacing-4x;
      }
    }
  }
}

// Radio buttons
input[type='radio'] {
  &.radio {
    @extend .visually-hidden;
    padding-left: 15px;
    & + label.radio {
      font-weight: $regular;
      display: inline-block;
      position: relative;
      font-size: 1rem;
      margin: 4px 12px;
      margin-left: 30px;

      &:before {
        position: absolute;
        left: -30px;
        content: ' ';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px $mid-gray--darkest;
        background-color: $mid-gray--lighter;
        padding-left: 15px;
      }
    }
    &:focus,
    &:hover {
      & + label.radio:before {
        box-shadow: inset 0 0 0 1px $green;
        background-color: $light-mint-green;
        cursor: pointer;
      }
    }

    &:checked {
      & + label.radio {
        &:before {
          position: absolute;
          left: -30px;
          content: ' ';
          width: 18px;
          height: 18px;
          background-color: $green;
          color: $full-white;
          box-shadow: none;
        }

        &:after {
          content: ' ';
          position: absolute;
          top: 4px;
          left: -26px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $full-white;
        }
      }
    }
  }

  &:disabled,
  &.disabled {
    &.radio {
      & + label.radio {
        color: $brown;
        &:before {
          cursor: not-allowed;
          background-color: $light-gray--primary;
          border-color: 1px solid $mid-gray--lightest;
          box-shadow: none;
        }
      }
    }
  }
}

.tabbable {
  padding: $spacing-1x $spacing-2x;
  background-color: $mid-gray;
  border-bottom: 0;
  color: $dark-gray--primary;
  border-top-left-radius: $border-radius-soft;
  border-top-right-radius: $border-radius-soft;
  outline: none;
  margin-right: 4px;
  &:hover {
    background-color: $pink--pale;
    color: $mid-red-secondary;
    box-shadow: 0 0 0 1px $pink;
  }

  &.active,
  &:focus {
    color: $dark-gray--primary;
    background-color: $full-white;
  }

  &.right {
    float: right;
  }

  &.cta {
    background-color: $warning-yellow;
    color: $full-white;
  }

  &.disabled,
  &:disabled {
    background-color: $highlighted-gray;
    color: $dark-gray;
    &:hover {
      cursor: not-allowed;
      background-color: $highlighted-gray;
      box-shadow: none;
    }
  }

  @include phone {
    .tabbable {
      padding: 8px 4px;
      font-size: 14px;
    }
  }
}

/* Toggle buttons */
.lbl {
  position: relative;
  height: 24px;
  width: 48px;
  background: $mid-gray--darkest;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    position: absolute;
    left: 0px;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background: white;
    content: '';
    transition: all 0.3s ease;
    border: 2px $mid-gray--darkest solid;
  }

  &:active {
    &:after {
      transform: scale(1.15, 0.85);
    }
  }

  &.center {
    margin: 0 auto;
  }

  .toggle-icon {
    z-index: 1;
    position: inherit;
    font-size: 16px;
    left: 4px;
    top: 4px;
    color: $mid-gray--darkest;
    transition: all 0.3s ease;
  }
}

/* Checkboxes */
.cbx:checked ~ label {
  background: $green;
  &:after {
    left: 24px;
    border: 2px $green solid;
  }
}

.cbx:checked ~ label span {
  color: $green;
  left: 29px;
}

.cbx:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
  &:after {
    background: #bcbdbc;
    border: none;
  }
}

// TODO remove or update margins to new standard
.form-group {
  margin: 6px 0 30px 0;
}

input.input--compact {
  border-width: map-get($border-widths, thin);
  border-style: solid;
  border-radius: 10px;
  background-color: $full-white;
  padding: 0 $spacing-1x;
  &.ng-invalid {
    border-color: $error-red;
  }
  &.ng-valid {
    border-color: $positive-feedback--green;
  }
  &.ng-pristine {
    border-color: $mid-gray--darker;
  }
  &:focus,
  &.ng-focus {
    border-color: #007aff;
    border-width: 2px;
  }
}
