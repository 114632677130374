/* Headings */
h1 {
  font-size: 2.25rem;
  font-weight: $regular;
  line-height: 1.67;
  margin: 0;
  color: $dark-red-secondary;
}
h2 {
  font-size: 1.875rem;
  font-weight: $regular;
  line-height: 1.37;
  color: $dark-red-secondary;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: $regular;
  line-height: 1.375;
  color: $dark-red-secondary;
  margin-top: 20px;
  margin-bottom: 10px;
}
// TODO not supported anymore?
h4 {
  font-size: 1rem;
  font-weight: $regular;
  margin-top: 10px;
  margin-bottom: 10px;
}
h5 {
  font-size: 0.875rem;
  font-weight: $regular;
  margin: 10px 0px;
}

/* Font sizes */
p,
.text--medium {
  font-size: 1rem;
  font-weight: $regular;
  line-height: 1.375;
}

.text--small {
  font-size: 0.75rem;
  font-weight: $regular;
  line-height: 1.4167;
}

.text--large {
  font-size: 1.1875rem;
  font-weight: $regular;
  line-height: 1.369;
}

.text--xl {
  font-size: 2.5rem;
}

/* Font colors */
.text--muted,
.text-muted {
  color: $mid-gray--darker !important;
}

.text--white,
.text--primary,
.text-primary {
  color: $full-white;

  &.shadow {
    text-shadow: 0px 0px 2px black;
  }
}

.text--success,
.text-success {
  color: $positive-feedback--green !important;
}

.text--danger,
.text--error,
.text-danger {
  color: $error-red !important;
}

.text--warning,
.text-warning,
.text--cta,
.text-cta {
  color: $warning-yellow !important;
}

.text--info,
.text-info {
  color: $tv4-red !important;
}

.text--gray {
  color: $dark-gray !important;
}

.text--darker-gray {
  color: $darker-gray !important;
}

.text--dark-red {
  color: $dark-red-secondary !important;
}

.text--dark-gray {
  color: $dark-gray--primary !important;
}

.text--black {
  color: black !important;
}

/* Font weights */
.text--strong,
.strong {
  font-weight: $bold !important;
}

.text--semi-strong,
.semi-strong {
  font-weight: $medium !important;
}

.text--normal {
  font-weight: $regular !important;
}

.text--thin,
.thin {
  font-weight: $light !important;
}

/* Font stylings */
.text--italic {
  font-style: italic !important;
}

.text--center {
  text-align: center !important;
}

.text--underline {
  text-decoration: underline !important;
}

.normalize--height {
  line-height: 1;
}

// Selector works only on block or inline-block elements
.capitalize--first-letter {
  &:first-letter {
    text-transform: uppercase;
  }
}

.text--left,
.text-left {
  text-align: left;
}

.text--right,
.text-right {
  text-align: right;
}

/* 758 - 1 */
@include phone {
  h1 {
    font-size: 1.625rem;
    font-weight: $bold;
    line-height: 2.308;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: $bold;
    line-height: 1.375;
  }
  h3 {
    font-size: 1.375rem;
    font-weight: $medium;
    line-height: 1.375;
  }
  h4 {
    font-size: 1rem;
    font-weight: $medium;
  }
  h5 {
    font-size: 0.875rem;
    font-weight: $medium;
  }
}
